.menu {
    width: 580px;
    margin: 0;
}

.menu > ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
}

.menu > ul > li {
    color: white;
}

.navigation {
    width: 650px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;

}
.navigation > a {
    text-decoration: none;
}

.announcement > a{
    color: white;
    text-decoration: none;
}

.account {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.grozny_round {
    width: 45px;
    height: 45px;
}

.account > .grozny_round > img {
    display: block;
    width: 100%;
}

.account > .p {
    margin: 0;
    padding-left: 10px;
    color: white;
    cursor: pointer;
}

/*.dropdown {*/
/*    position: relative;*/
/*    display: inline-block;*/
/*}*/

.dropdown_content {
    /*display: none;*/
    position: absolute;
    top: 55px;
    left: -60px;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

/*.dropdown:hover .dropdown_content {display: block;}*/