.container {
    width: 1320px;
    margin: 10px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo > a > img{
    width: 210px;
    height: 90px;
    border-radius: 20px;
}

.search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
}

.services_container {
    width: 810px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    flex-wrap: wrap;
}

.services_container > div {
    background-color: #011765;
    text-align: center;
    width: 190px;
    margin: 10px 0 0 0;
    color: #ffff;
    border-radius: 17px;
}

.services_container > div > span {
    display: block;
    font-size: 20px;
    padding: 10px 10px;
    white-space: pre-line;
}

.services_container > div:hover {
    background-color: #5b5656;
    transition: 0.3s;
    cursor: pointer;
}

.recomendation_container {
    width: 1320px;
    margin: 100px auto 0 auto;
}