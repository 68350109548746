.title_adversitement > span{
    display: block;
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
}

.photo_adversitement > span{
    display: block;
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
    margin-top: 40px;
}

.description_adversitement > span {
    display: block;
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
    margin-top: 40px;
}

.description_adversitement > textarea {
    height: 220px;
    resize: none;
}

.price_adversitement > span {
    display: block;
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
    margin-top: 40px;
}

.button_adversitement {
    width: 750px;
    margin: 100px auto;
}

.title_adversitement > input,
.photo_adversitement > input,
.description_adversitement > textarea,
.price_adversitement > input
{
    width: 700px;
    padding: 8px;
    border-radius: 8px;
    border: 0.5px solid gray;
    color: white;
    background-color: #9b9494;
    font-weight: bold;
}

.title_adversitement > input::-webkit-input-placeholder,
.photo_adversitement > input::-webkit-input-placeholder,
.price_adversitement > input::-webkit-input-placeholder {
    color: white;
}

.title_adversitement > input:focus,
.photo_adversitement > input:focus,
.description_adversitement > textarea:focus,
.price_adversitement > input:focus
{
    outline: none;
}