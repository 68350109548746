* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
}

.container {
    width: 1320px;
    margin:  30px auto 0 auto;
    display: flex;
    justify-content: space-between;
}

.container-3 {
    width: 1300px;
    margin:  auto;
}

.container_info {
    width: 1100px;
    display: flex;
    justify-content: space-between;
}

.price > p {
    font-size: 35px;
    font-weight: bold;
}

.price > p:nth-child(2) {
    font-size: 19px;
    color: #a9a6a6;
    margin-top: -20px;
}

.text-button {
    display: block;
    padding-left: 8px;
}

/*-------------------------------------------------------------------------------------------------------------------------------------------------*/
.slider_container {
    width: 900px;
    height: 960px;
    margin-top: 30px;
}

/*.swiper {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*}*/

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/*.swiper {*/
/*    width: 100%;*/
/*    height: 300px !important;*/
/*    margin-left: auto;*/
/*    margin-right: auto;*/
/*}*/

.swiper-slide {
    background-size: cover;
    background-position: center;
}

.mySwiper2 {
    height: 80%;
    width: 100%;
}

.mySwiper {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0 10px 0;
    background-color: #fff;
}

.mySwiper .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.6;
    filter: blur(3px);
}

.mySwiper .swiper-slide-thumb-active {
    opacity: 1;
    filter: blur(0);
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.sliders_and_buttons {
    display: flex;
    justify-content: space-between;
}

.buttons_right {
    margin-top: 30px;
}

.left_info > p > span {
    font-weight: 600;
    color: #919191;
}

.left_info > p {
    font-weight: bold;
    color: black;
}

.right_info > p > span {
    font-weight: 600;
    color: #919191;
}

.right_info > p  {
    font-weight: bold;
    color: black;
}

.description {
    width: 650px;
}

.description > p {
    letter-spacing: 2px;
    white-space: pre-line;
}

.footer > ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
}

.footer > ul > li, .basement {
    font-size: 14px;
    font-weight: 500;
}

.container_footer {
    width: 1320px;
    margin: auto;
    text-align: center;
}