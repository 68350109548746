.adversitement {
    width: 70%;
}

.actived, .archived {
    display: block;
    font-size: 21px;
    font-weight: 600;
    padding-bottom: 10px;
}

.border_bottom {
    border-bottom: 1px solid #e0d9d9;
}

.active_and_archive {
    display: flex;
    justify-content: space-between;
    width: 220px;
}

.actived {
    margin-right: 20px;
    border-bottom: 2px solid black;
}

.adversitement_parent {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-top: 30px;
}

.adversitement_image {
    width: 300px;
    height: 300px;
}

.adversitement_image > img {
    display: block;
    width: 100%;
}

.adversitement_text {
    width: 290px;
}

.course {
    font-size: 23px;
    font-weight: 600;
    color: #0382F7;
}

.price {
    font-size: 17px;
    font-weight: bold;
}

.location {
    font-size: 15px;
    color: #A0A0A0;
}