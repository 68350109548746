.container {
    width: 1320px;
    margin: 40px auto;
    display: flex;
    justify-content: space-between;
}

/*Menu*/

.menu {
    width: 20%;
}
.menu > ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.menu > ul > li {
    padding: 11px;
    cursor: pointer;
    margin-bottom: 10px;
}

.menu > ul > li:hover {
    background-color: #0029c4;
    color: #fff;
    width: 200px;
    border-radius: 15px;
}

.menu_logo {
    width:  170px;
    height: 166px;
}

.menu_title {
    width: 170px;
}
.menu_logo  > img {
    width: 100%;
    display: block;
}

.menu_title {
    display: block;
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    padding-top: 20px;
}

.menu_raiting {
    display: flex;
    align-items: center;
    margin-top: 20px;

}

.menu_raiting > span {
    display: block;
    font-size: 25px;
    font-weight: 600;
}

.menu_raiting_star {
    padding-left: 20px;
    color: orange;
}

.active {
    background-color: #011765;
    color: #fff;
    width: 200px;
    padding-left: 30px !important;
    border-radius: 10px;
}


/*Messages*/


.messages {
    width: 70%;
}

.messages > span {
    font-size: 30px;
    font-weight: 600;
}

.messages_button {
    width: 80%;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}

.search_form > input{
    padding: 8px;
    background-color: #c9c9c9;
    border-radius: 10px;
    border: 1px solid gray;
}

.search_form > input:focus {
    outline: none;
}
.search_form > input::-webkit-input-placeholder {
    color: #656262;
}

.chat {
    background-color: #EBEBEB;
    display: flex;
    margin: 30px 0 20px 0;
    padding: 10px;
    border-radius: 15px;
}
.messages_right_block {
    display: flex;
    flex-direction: column;
}
.messages_right_block  p {
    margin: 0;
    padding-top: 10px;
}
.avatar_user {
    padding: 10px;
}

.messages_userName, .title_ad, .message_user {
    padding-left: 20px;
}
.messages_userName > p {
    font-size: 22px;
    font-weight: 600;
}

.title_ad > p{
    font-size: 15px;
}

.message_user > p {
    font-size: 14px;
}